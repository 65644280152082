import {DebouncedFunc, throttle} from 'lodash';
import Cookies from 'js-cookie';

import RestApiHelper from './RestApiHelper';
import SsoSpringConfig from './SsoSpringConfig';
import {SsoNotification, User, UserNotification} from '../types';
import {Duration, DurationType} from '../util';

export class SsoSpringRestApi {
  private _ssoSpringConfig: SsoSpringConfig;
  private _throttledBumpLastActivity?: DebouncedFunc<() => void>;
  private _globalLastActivityEventHandlersBound: boolean;
  private readonly _restApiHelper: RestApiHelper;
  private readonly _removeSessionData = () => {
    // Remove the lastActivity cookie and JWT in local storage
    Cookies.remove(
      this._ssoSpringConfig.lastActivityCookieName,
      {
        domain: this._ssoSpringConfig.lastActivityCookieDomain
      }
    );
    localStorage.removeItem(this._ssoSpringConfig.jwtKey);
  };

  constructor(ssoSpringConfig: SsoSpringConfig) {
    this._ssoSpringConfig = ssoSpringConfig;
    this._restApiHelper = new RestApiHelper({
      path: ssoSpringConfig.apiUrl,
      jwtKey: ssoSpringConfig.jwtKey,
      // Redirect to login by default for any unauthorized requests
      unauthorizedHandler: this.redirectToLogin,
      defaultFetchConfig: {
        credentials: 'include' // Included credentials by default, so needed cookies on base domain are included
      }
    });
    this._globalLastActivityEventHandlersBound = false;
  }

  get restApiHelper() {
    return this._restApiHelper;
  }

  // The method to log the user in by username and password
  login = async (username: string, password: string): Promise<string> => {
    this.bumpLastActivityCookie();

    const jwt = await this._restApiHelper.postWithTextResponse(
      'client-auth/login',
      {
        headers: {
          'Authorization': 'Basic ' + btoa(username + ':' + password)
        }
      }, {
        // The caller is responsible for handling any unauthorized failures in this case.
        // In this case the only caller is the SSO Login component.
        suppressUnauthorizedHandler: true
      }
    );
    localStorage.setItem(this._ssoSpringConfig.jwtKey, jwt);
    return jwt;
  };

  // Used to attempt a login from a refresh token. This is intended to only called once when an application loads.
  loginFromRefreshToken = async () => {
    const jwt = await this._restApiHelper.postWithTextResponse(
      'client-auth/jwt',
      {},
      {
        // Suppress the unauthorized handler for this call
        suppressUnauthorizedHandler: true,
        // Don't count this request as part of user activity since it runs in the background
        ignoreLastActivity: true
      }
    );
    localStorage.setItem(this._ssoSpringConfig.jwtKey, jwt);
  };

  // Used to get all the user's details which will be used to assist in rendering decisions
  currentUser = async (): Promise<User> => {
    return await this._restApiHelper.getWithJsonResponse('users/current-user');
  };

  // This is intended to be used when user clicks sign out button. It will not set a redirect URL.
  signOut = async (): Promise<void> => {
    try {
      await this._restApiHelper.deleteWithEmptyResponse('client-auth/jwt');
    } finally {
      this._removeSessionData();
      window.location.href = `${this._ssoSpringConfig.webUrl}/login?signOut=true`;
    }
  };

  // This is the same as the signOut method except a redirect URL parameter will be set in the login URL.
  // If the user signs in again, SS0 will redirect to this URL. This is intended to be used when a user
  // goes to the URL of an application, isn't authorized for whatever reason, but should be redirected
  // back to that URL after they successfully sign in.
  redirectToLogin = async () => {
    try {
      // If token was already deleted, not need to call API to delete server side cookies
      if (localStorage.getItem(this._ssoSpringConfig.jwtKey)) {
        await this._restApiHelper.deleteWithEmptyResponse('client-auth/jwt');
      }
    } finally {
      this._removeSessionData();
      if (!window.location.href.startsWith(`${this._ssoSpringConfig.webUrl}/login`)) {
        const currentLocation = window.location.href;
        const newUrl = new URL('login', this._ssoSpringConfig.webUrl);
        newUrl.search = new URLSearchParams({signOut: 'true', redirect: currentLocation}).toString();
        window.location.href = newUrl.toString();
      }
    }
  };

  // Used to retrieve notifications.
  findNotifications = async (ignoreLastActivity: boolean = true): Promise<UserNotification> => {
    // Avoid calls that will fail if local storage for cleared from a logout
    if (!localStorage.getItem(this._ssoSpringConfig.jwtKey)) {
      return {
        unread: [],
        read: [],
        archived: []
      } as UserNotification;
    } else {
      return await this._restApiHelper.getWithJsonResponse('notifications',
        {},
        {
          // When this is called in the background for the AppBar don't count this as active user activity
          // If it is called on the notifications screen it should count as active user activity
          ignoreLastActivity,
          // The caller is responsible to handling any unauthorized failures in this case
          suppressUnauthorizedHandler: true
        }
      );
    }
  };

  // Used to read a notification.
  readNotification = async (notificationId: number): Promise<SsoNotification> => {
    return await this._restApiHelper.putWithJsonResponse(`notifications/${notificationId}/read`,
      {},
      {
        // The caller is responsible to handling any unauthorized failures in this case
        suppressUnauthorizedHandler: true
      }
    );
  };

  // Used to archive a notification.
  archiveNotification = async (notificationId: number): Promise<SsoNotification> => {
    return await this._restApiHelper.putWithJsonResponse(`notifications/${notificationId}/archive`,
      {},
      {
        // The caller is responsible to handling any unauthorized failures in this case
        suppressUnauthorizedHandler: true
      }
    );
  };

  // Return an interval that checks and maintains the user's session based on last activity
  monitorSession = (sessionWarningHandler: () => void) => {
    this.bindGlobalLastActivityEventHandlers();

    const checkSession = async () => {
      const isOnFaqPage = window.location.pathname === '/faq';
      const isOnLoginPage = window.location.href.startsWith(`${this._ssoSpringConfig.webUrl}/login`);

      // If user is on FAQ or Login screens ignore
      if (isOnFaqPage || isOnLoginPage) {
        return;
      }

      this.bindGlobalLastActivityEventHandlers();

      // If a JWT is present we can assume the user is signed in and we need to check how long they have been active
      if (localStorage.getItem(this._ssoSpringConfig.jwtKey)) {
        if (this.timeLeftInSession() <= 0) {
          await this.redirectToLogin();
        } else if (this.timeLeftInSession() <= this._ssoSpringConfig.sessionWarningAt) {
          // Renew the token if it is close to expiration (2 minutes left). This does not prevent
          // the user from getting signed out if they are inactive for too long. That is what the check
          // before this one handles.

          if (sessionWarningHandler) {
            sessionWarningHandler();
          } else {
            console.debug('SSO: No SessionWarningHandler provided; session is at 2 minute warning.');
          }

          try {
            const jwt = await this._restApiHelper.postWithTextResponse(
              'client-auth/jwt',
              {},
              {
                // Suppress the unauthorized handler for this call
                suppressUnauthorizedHandler: true,
                // don't count this request as part of user activity since it runs in the background
                ignoreLastActivity: true
              }
            );
            localStorage.setItem(this._ssoSpringConfig.jwtKey, jwt);
          } catch (e) {
            // If a network happened failed gracefully, otherwise something is wrong with the refresh token, so logout out
            if (e instanceof TypeError) {
              console.debug('Refresh token could not be retrieved. Failing silently in hopes this was just a network hiccup.', e);
            } else {
              console.debug('SSO: A non-network error happened when trying to get a refresh token; signing out', e);
              // A non-network error happened when trying to get a new refresh token, so signing out.
              await this.redirectToLogin();
            }
          }
        }
      } else {
        // If the user doesn't have a JWT, this likely means another tab logged them out.
        await this.redirectToLogin();
      }
    };

    return setInterval(
      async () => await checkSession(),
      Duration.of(1, DurationType.SECONDS)
    );
  };

  sessionDataExists = (): boolean => {
    return !!Cookies.get(this._ssoSpringConfig.lastActivityCookieName) &&
      !!localStorage.getItem(this._ssoSpringConfig.jwtKey);
  };

  sessionTimeLeftGreaterThanWarningThreshold = (): boolean => {
    if (this.lastActivityAt() !== undefined) {
      return this.timeLeftInSession() >= this._ssoSpringConfig.sessionWarningAt;
    } else {
      return false;
    }
  };

  bumpLastActivity = (): void => {
    // Throttle this function so that it is only called at most once every 15 seconds
    if (!this._throttledBumpLastActivity) {
      const bump = async () => {
        if (localStorage.getItem('JWT')) {
          this.bindGlobalLastActivityEventHandlers();
          this.bumpLastActivityCookie();

          const jwt = await this._restApiHelper.postWithTextResponse(
            'client-auth/jwt',
            {},
            {
              // Suppress the unauthorized handler for this call
              suppressUnauthorizedHandler: true,
              // don't count this request as part of user activity since it runs in the background
              ignoreLastActivity: true
            }
          );
          localStorage.setItem(this._ssoSpringConfig.jwtKey, jwt);
        }
      };

      this._throttledBumpLastActivity = throttle(bump, Duration.of(15, DurationType.SECONDS));
    }

    return this._throttledBumpLastActivity();
  };


  lastActivityAt(): Date | undefined {
    if (!Cookies.get(this._ssoSpringConfig.lastActivityCookieName)) {
      return undefined;
    }

    const lastActivityCookieValue = Cookies.get(this._ssoSpringConfig.lastActivityCookieName);
    return lastActivityCookieValue !== undefined ? new Date(lastActivityCookieValue) : undefined;
  }

  lastActivityAtTimeElapsed(): number | undefined {
    if (this.lastActivityAt() !== undefined) {
      return new Date().getTime() - this.lastActivityAt()!.getTime();
    } else {
      return undefined;
    }
  }

  timeLeftInSession(): number | 0 {
    if (this.lastActivityAtTimeElapsed() !== undefined) {
      return this._ssoSpringConfig.maxSession - this.lastActivityAtTimeElapsed()!;
    } else {
      return 0;
    }
  }

  isSessionWarningModalOpen(): boolean {
    return !!(localStorage.getItem(this._ssoSpringConfig.sessionExpirationModalOpenKey) &&
      (localStorage.getItem(this._ssoSpringConfig.sessionExpirationModalOpenKey) === 'true'));
  }

  setSessionWarningModalOpen(isOpen: boolean) {
    if (isOpen) {
      // Remove event listeners so they aren't captured when the modal is open
      window.removeEventListener('keydown', this.bumpLastActivity, true);
      window.removeEventListener('click', this.bumpLastActivity, true);
      window.removeEventListener('mousemove', this.bumpLastActivity, true);

      this._globalLastActivityEventHandlersBound = false;
    }

    localStorage.setItem(this._ssoSpringConfig.sessionExpirationModalOpenKey, isOpen.toString());
  }

  private bumpLastActivityCookie() {
    Cookies.set(this._ssoSpringConfig.lastActivityCookieName,
      new Date().toISOString(),
      {
        domain: this._ssoSpringConfig.lastActivityCookieDomain,
        secure: window.location.protocol.startsWith('https'),
        expires: 1 // expires in 1 day. Also gets removed when an explicit sign out or timeout happens
      }
    );
  }

  private bindGlobalLastActivityEventHandlers() {
    if (!this._globalLastActivityEventHandlersBound && !this.isSessionWarningModalOpen()) {
      window.addEventListener('keydown', this.bumpLastActivity, true);
      window.addEventListener('click', this.bumpLastActivity, true);
      window.addEventListener('mousemove', this.bumpLastActivity, true);

      this._globalLastActivityEventHandlersBound = true;
    }
  }
}

export default SsoSpringRestApi;