import React, {memo} from 'react';
import {Container} from 'reactstrap';

const NotFound = () => <Container>
  <div className="NotFound d-flex flex-column justify-content-center">
    <div className="title text-center text-uppercase">Oops! Page not found.</div>
    <div className="four-zero-four text-center">404</div>
    <div className="lead text-center text-uppercase">We are sorry, but the page you requested was not found.</div>
  </div>
</Container>;

export default memo(NotFound);
