import {createContext} from 'react';

import {JurisdictionRole, User} from '@reasoncorp/kyber-js';
import {RoleJurisdictionMapping} from '../types';

export type SsoAppContextType = {
  currentUser: User | null
  setCurrentUser: (user: User | null) => void
  isAdmin: boolean
  isCsr: boolean
  isAuditor: boolean
  isMegAdmin: boolean,
  isCertsAdmin: boolean,
  isCertifiedUser: boolean,
  hasReportAccess: boolean,
  hasMiMessagingAuditReportAccess: boolean,
  jurisdictionRoles: JurisdictionRole[],
  setJurisdictionRoles: (jurisdictionRoles: JurisdictionRole[]) => void
  roleJurisdictionMappings: RoleJurisdictionMapping[]
};

export const SsoAppContext = createContext<SsoAppContextType>({
  currentUser: null,
  setCurrentUser: (user: User | null) => user,
  isAdmin: false,
  isCsr: false,
  isAuditor: false,
  isMegAdmin: false,
  isCertsAdmin: false,
  isCertifiedUser: false,
  hasReportAccess: false,
  hasMiMessagingAuditReportAccess: false,
  jurisdictionRoles: [],
  setJurisdictionRoles: (jurisdictionRoles: JurisdictionRole[]) => jurisdictionRoles,
  roleJurisdictionMappings: []
});