import React, {memo} from 'react';
import {Container} from 'reactstrap';

const NotFoundV2 = () => <Container>
  <div className="NotFoundV2 d-flex flex-column justify-content-center">
    <div className="title text-center text-uppercase">The page you requested was not found.</div>
    <div className="four-zero-four text-center">404</div>
  </div>
</Container>;

export default memo(NotFoundV2);
