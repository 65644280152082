import rg4js from 'raygun4js';

interface RaygunOptions {
  apiKey: string;
  tags?: string[];
  version?: string;
}

const configureRaygun = (options: RaygunOptions) => {
  // Don't report errors to Raygun when in a development environment.
  if (window.location.hostname === 'localhost') {
    return;
  }
  rg4js('apiKey', options.apiKey);
  rg4js('enableCrashReporting', true);
  rg4js('options', {
    ignoreAjaxAbort: true,
    ignoreAjaxError: true,
    ignore3rdPartyErrors: true,
    captureUnhandledRejections: false
  });

  if (options.tags) {
    rg4js('withTags', options.tags);
  }
  if (options.version) {
    rg4js('setVersion', options.version);
  }
};

export default configureRaygun;
