import React, {memo} from 'react';
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

type Props = {
  profileText?: string
  handleProfileClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleSignOut: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const UserProfile = ({
                       profileText = 'My Profile',
                       handleProfileClick,
                       handleSignOut
                     }: Props) => <UncontrolledDropdown nav inNavbar className="UserProfile">
  <DropdownToggle nav title="User Profile" aria-label="user-profile-button" className="btn btn-link">
    <FontAwesomeIcon icon="user-circle"/>
  </DropdownToggle>
  <DropdownMenu right>
    <DropdownItem className="profile-text"
                  onClick={handleProfileClick}>
      {profileText}
    </DropdownItem>
    <DropdownItem className="sign-out-text"
                  onClick={handleSignOut}>
      Sign Out
    </DropdownItem>
  </DropdownMenu>
</UncontrolledDropdown>;

export default memo(UserProfile);
