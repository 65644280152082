import React, {memo, useMemo} from 'react';

import Footer from './Footer';

const MiFooter = () => {
  const footerProps = useMemo(() => ({
    brand: 'State of Michigan',
    brandLink: 'https://www.michigan.gov/',
    footerLinks: [{
      name: 'Policies',
      to: 'https://www.michigan.gov/policies'
    }]
  }), []);

  return <Footer {...footerProps} />;
};

export default memo(MiFooter);
