import React, {memo, useMemo} from 'react';

type FooterLink = {
  name: string
  to: string
}

type Props = {
  brand: string
  brandLink: string
  footerLinks: FooterLink[]
}

const Footer = ({
                  brand,
                  brandLink,
                  footerLinks
                }: Props) => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  const externalLinkProps = useMemo(() => ({
    target: '_blank',
    rel: 'noopener noreferrer'
  }), []);

  const renderLink = useMemo(() => (footerLink: FooterLink) => {
    return (
      <li key={footerLink.name}>
        <a className="footer-link" href={footerLink.to} {...externalLinkProps}>
          {footerLink.name}
        </a>
      </li>
    );
  }, []);

  return <footer className="Footer d-flex justify-content-between">
    <div>
      <span className="footer-brand mr-1">Copyright {currentYear}</span>
      <a className="footer-brand" href={brandLink} {...externalLinkProps}>{brand}</a>
    </div>
    <ul className="footer-links d-flex justify-content-end">
      {footerLinks.map(renderLink)}
    </ul>
  </footer>;
};

export default memo(Footer);
