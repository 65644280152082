import React, {memo, useMemo} from 'react';

import {BootstrapColor} from '../types';

type Props = {
  className?: string
  color?: BootstrapColor
}

const ProgressIndicator = ({
                             className,
                             color = 'primary'
                           }: Props) => {
  const classNameToUse = useMemo(() => className ? `ProgressIndicator ${className}` : 'ProgressIndicator', []);
  const trackClassName = useMemo(() => `track bg-${color}`, []);
  const backgroundClassName = useMemo(() => `background ${color}`, []);

  return <div className={classNameToUse}>
    <div className={trackClassName}/>
    <div className={backgroundClassName}/>
  </div>;
};

export default memo(ProgressIndicator);
