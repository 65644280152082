import React, {ReactNode, useCallback, useMemo, useState} from 'react';
import {NavLink as RouterLink} from 'react-router-dom';
import {Badge, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';

import {AppBarRoute} from '../types';

type Props = {
  brandLink: string
  brandImage: string
  brandImageAlt: string
  appName: string
  environmentName?: string
  organizationName: string
  routes?: AppBarRoute[]
  renderRoutes?: () => ReactNode
  renderNotificationButton?: () => ReactNode
  renderUserProfile?: () => ReactNode
  light?: boolean
  dark?: boolean
  expand?: 'sm' | 'md' | 'lg'
}

const AppBar = ({
                  brandLink,
                  brandImage,
                  brandImageAlt,
                  appName,
                  environmentName,
                  organizationName,
                  routes = [],
                  renderRoutes,
                  renderNotificationButton,
                  renderUserProfile,
                  light = true,
                  dark = false,
                  expand = 'md'
                }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleNavbarToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  // Clicking on a nav link does not automatically close the expanded toggler hence this function.
  const handleNavLinkClick = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [isOpen]);

  const defaultRenderRoutes = useMemo(() => {
    return routes?.filter(route => !route.hide)
      .map((route) => <NavItem className="pr-1" key={route.name}>
        <NavLink tag={RouterLink}
                 to={route.to}
                 end={route.exact}
                 onClick={handleNavLinkClick}>
          {route.name}
        </NavLink>
      </NavItem>);
  }, [routes, handleNavLinkClick]);

  return (
    <Navbar className="AppBar"
            aria-label="navigation"
            role="navigation"
            light={light}
            dark={dark}
            expand={expand}>
      <NavbarBrand tag="a" href={brandLink} className="logo">
        <img src={brandImage} alt={brandImageAlt}/>
      </NavbarBrand>
      <NavbarBrand tag="div"
                   className="app-info">
        <h1 className="app-name">
          {appName}
          {environmentName && <Badge color="warning" className="text-uppercase ml-2">
            {environmentName}
          </Badge>}
        </h1>
        <p className="organization-name">{organizationName}</p>
      </NavbarBrand>
      <NavbarToggler onClick={handleNavbarToggle}/>
      <Collapse isOpen={isOpen} navbar>
        <Nav navbar className="ml-auto">
          {renderRoutes ? renderRoutes() : defaultRenderRoutes}
          {renderNotificationButton && renderNotificationButton()}
          {renderUserProfile && renderUserProfile()}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default AppBar;