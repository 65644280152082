import React, {memo, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

type BreadcrumbItemType = {
  text: string
  icon?: IconProp
  route?: string
  active?: boolean
}

type Props = {
  breadcrumbs: BreadcrumbItemType[]
  inline?: boolean
}

const BreadcrumbsNav = ({
                          breadcrumbs,
                          inline = false
                        }: Props) => {
  const renderBreadCrumb = useMemo(() => (breadcrumb: BreadcrumbItemType, index: number) => {
    const icon = breadcrumb.icon ? <FontAwesomeIcon icon={breadcrumb.icon} className="mr-2"/> : null;
    if (breadcrumb.active || !breadcrumb.route) {
      return (
        <BreadcrumbItem key={index} active>
          {icon}
          {breadcrumb.text}
        </BreadcrumbItem>
      );
    } else {
      return (
        <BreadcrumbItem key={index}>
          <Link to={breadcrumb.route}>
            {icon}{breadcrumb.text}
          </Link>
        </BreadcrumbItem>
      );
    }
  }, []);

  const className = useMemo(() => {
    return inline ? "BreadcrumbsNav BreadcrumbsNavInline" : "BreadcrumbsNav";
  }, [inline])

  return <Breadcrumb tag="nav" className={className}>
    {breadcrumbs.map(renderBreadCrumb)}
  </Breadcrumb>;
};

export default memo(BreadcrumbsNav);
