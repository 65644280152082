import React, {useEffect, useRef, useState} from 'react';
import {FormFeedback, FormGroup, Input, Label} from 'reactstrap';
import {useField, useFormikContext} from 'formik';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import FormikSelect from './FormikSelect';

import {BootstrapFormControlSize} from '../types';

type AutoCompleteOption = {
  value: any;
  displayValue: string;
}

enum MatchType {
  EXACT = 'EXACT',
  START = 'START',
  END = 'END',
  MIDDLE = 'MIDDLE'
}

type AutoCompleteSuggestion = AutoCompleteOption & {
  sort: number;
  matchType: MatchType;
}

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  [key: string]: any
  id?: string
  name: string
  bsSize?: BootstrapFormControlSize
  labelText?: string
  ariaLabel?: string
  icon?: IconProp
  disabled?: boolean
  noFormikOnChange?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  options: AutoCompleteOption[] | string[]
  disableFloatingLabel?: boolean
  caseSensitive?: boolean
  noResultsMessage?: string
  showAllOnBlank?: boolean
  autoFocus?: boolean
}

const buildOption = (matchType: MatchType, sort: number) => (option: AutoCompleteOption) => ({
  matchType,
  sort,
  ...option
});

const autocomplete = (inputValue: number | string | null | undefined,
                      options: AutoCompleteOption[] = [],
                      caseSensitive: boolean,
                      showAllOnBlank: boolean) => {
  const inputValueIsBlank = inputValue === undefined || inputValue === '' || inputValue === null || typeof inputValue !== 'string';
  if (!showAllOnBlank && inputValueIsBlank) {
    return [];
  }

  // Handle what is search for in array of options based on whether component is case sensitive or not.
  // if it is case insensitive, a lower-cased value is used for matching
  const inputValueAsString = inputValue === undefined || inputValue === null || typeof inputValue !== 'string' ?
    '' : inputValue;
  const searchValue = caseSensitive ? inputValueAsString : inputValueAsString.toLowerCase();
  const optionValue = (option: AutoCompleteOption) => caseSensitive ?
    option.displayValue : option.displayValue.toLowerCase();

  // Build suggestion list based on exact matches, matches at the start of options,
  // matches located in the middle of options, and matches at the ends of options.
  const matchedOptionsExact = options.filter(option => optionValue(option) === searchValue);
  const matchedOptionsStart = options.filter(option => optionValue(option).startsWith(searchValue));
  const matchedOptionsMiddle = options.filter(option => optionValue(option).includes(searchValue) &&
    !optionValue(option).startsWith(searchValue) &&
    !optionValue(option).endsWith(searchValue));
  const matchedOptionsEnd = options.filter(option => optionValue(option).endsWith(searchValue));

  // return all options when showAllOnBlank is enabled and the input value is blank
  if (showAllOnBlank && inputValueIsBlank) {
    return options.map(buildOption(MatchType.START, 1));
  }

  // If an exact match exists, return only that item.
  // exact matches appear first, and have a sort index of 0
  if (matchedOptionsExact.length > 0) {
    return matchedOptionsExact.map(buildOption(MatchType.EXACT, 0));
  }

  let results: AutoCompleteSuggestion[] = [];
  if (matchedOptionsStart.length > 0) {
    // matches at the start of strings appear first, and have a sort index of 1
    results = results.concat(matchedOptionsStart.map(buildOption(MatchType.START, 1)));
  }

  if (matchedOptionsMiddle.length > 0) {
    // matches in the middle of strings appear after matches at the start of strings, and have a sort index of 2
    results = results.concat(matchedOptionsMiddle.map(buildOption(MatchType.MIDDLE, 2)));
  }

  if (matchedOptionsEnd.length > 0) {
    // matches at the end of strings appear last, and have a sort index of 3
    results = results.concat(matchedOptionsEnd.map(buildOption(MatchType.END, 3)));
  }

  return results;
};

// Sort by sort index to show closest match first, then sort alphabetically by displayValue
const sortSuggestions = (a: AutoCompleteSuggestion, b: AutoCompleteSuggestion) => {
  return (a.sort === b.sort ? 0 : a.sort > b.sort ? 1 : -1) ||
    a.displayValue.localeCompare(b.displayValue);
};

const renderMiddleMatch = (fieldValue: string, displayValue: string) => {
  const indexOfMatch = displayValue.toLowerCase().indexOf(fieldValue.toLowerCase());
  const startText = displayValue.substring(0, indexOfMatch);
  const highlightedText = displayValue.substring(indexOfMatch, indexOfMatch + fieldValue.length);
  const endText = displayValue.substring(indexOfMatch + fieldValue.length, displayValue.length);

  return <>
    <span>{startText}</span>
    <strong>{highlightedText}</strong>
    <span>{endText}</span>
  </>;
};

const renderStartMatch = (fieldValue: string, displayValue: string) => {
  return <>
    <strong>{displayValue.substring(0, fieldValue.length)}</strong>
    <span>{displayValue.substring(displayValue.length, fieldValue.length)}</span>
  </>;
};

const renderEndMatch = (fieldValue: string, displayValue: string) => {
  return <>
    <span>{displayValue.substring(0, displayValue.length - fieldValue.length)}</span>
    <strong>{displayValue.substring(displayValue.length - fieldValue.length, displayValue.length)}</strong>
  </>;
};

const FormikAutoComplete = ({
                              id,
                              name,
                              bsSize,
                              labelText,
                              ariaLabel,
                              icon,
                              disabled,
                              noFormikOnChange,
                              onChange,
                              options,
                              disableFloatingLabel,
                              caseSensitive = false,
                              noResultsMessage = 'No Results',
                              showAllOnBlank = true,
                              autoFocus = false,
                              ...otherProps
                            }: Props) => {
  const idToUse = id ? id : `${name}AutoComplete`;
  const [itemIndex, setItemIndex] = useState<number>(-1);
  const [focused, setFocused] = useState<boolean>(false);
  const [hasSelectedSuggestion, setHasSelectedSuggestion] = useState<boolean>(false);
  const [hasBeenFocused, setHasBeenFocused] = useState(false);
  const [focusCount, setFocusCount] = useState(0);
  const [field, meta, helpers] = useField(name);
  const {isSubmitting, validateOnMount} = useFormikContext();
  const isInvalid = validateOnMount ? !!meta.error : hasBeenFocused && !!(meta.error && meta.touched);
  const [isScreenReader, setIsScreenReader] = useState(true);
  // options are mapped to an array of objects if an array of strings is passed as a prop
  const optionsAsObjects: AutoCompleteOption[] = options.length > 0 && typeof options[0] !== 'string' ?
    options as AutoCompleteOption[] : (options as string[]).map(option => ({
      value: option,
      displayValue: option
    }));

  const inputDisplayValue = optionsAsObjects.filter(option => option.value === field.value)?.[0]?.displayValue ??
    (field.value || '');
  // create list of suggestions based on input value, and sort
  const suggestions = autocomplete(inputDisplayValue, optionsAsObjects, caseSensitive, showAllOnBlank)
    .sort(sortSuggestions);
  const showSuggestions = showAllOnBlank ? (!hasSelectedSuggestion && focused)
    : (!hasSelectedSuggestion && focused && inputDisplayValue.trim() !== '');
  const hasNoResults = suggestions.length === 0;
  const showError = validateOnMount || ((hasNoResults || meta.touched) && !focused && hasBeenFocused);
  const optionsContainerRef = useRef<HTMLDivElement>(null);

  const scrollToCurrentOption = () => {
    const el = optionsContainerRef?.current;
    if (el) {
      const topPosition = (el?.querySelector('.highlight') as HTMLElement)?.offsetTop || 0;
      el?.scrollTo({top: topPosition, behavior: 'smooth'});
    }
  };

  // A ref to the div wrapping the autocomplete component is needed to properly handle onBlur events
  // an onBlur on the divs or FormGroup wrapping the component will not allow for click events
  // of suggestions to get triggered.
  // This creates an event handler for all clicks on the page outside the autocomplete component.
  // if it is not the div wrapping the autocomplete component, focused is set to false
  const autocompleteRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (!autocompleteRef?.current?.contains(event.target as HTMLElement)) {
      setItemIndex(-1);
      setFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const renderNoResults = <div className="NoResults">{noResultsMessage}</div>;

  const arrowDown = () => {
    if (itemIndex !== suggestions.length - 1) {
      setItemIndex(prevState => prevState + 1);
      setTimeout(() => {
        scrollToCurrentOption();
      }, 100);
    }
  };

  const arrowUp = () => {
    if (itemIndex !== -1) {
      setItemIndex(prevState => prevState - 1);
      setTimeout(() => {
        scrollToCurrentOption();
      }, 100);
    }
  };

  // selectItem is used to handle clicks on a suggestion
  // it focuses/highlights the suggestion, sets the value in formik, and hides the suggestions list
  const selectItem = (index: number, suggestion: AutoCompleteSuggestion) => {
    setItemIndex(index);
    helpers.setValue(suggestion.value);
    setHasSelectedSuggestion(true);
  };

  const handleKeyPress = (newValue: AutoCompleteSuggestion) => (event: React.KeyboardEvent) => {
    if ('Enter' === event.key && newValue !== undefined) {
      event.preventDefault();
      helpers.setValue(newValue.value);
      setFocused(false);
      setHasSelectedSuggestion(true);
    } else if ('Tab' === event.key && newValue !== undefined) {
      helpers.setValue(newValue.value);
      setFocused(false);
      setHasSelectedSuggestion(true);
    } else if (event.key === 'ArrowDown') {
      setAsFocused();
      arrowDown();
    } else if (event.key === 'ArrowUp') {
      setAsFocused();
      arrowUp();
    } else {
      setAsFocused();
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemIndex(-1);

    if (onChange) {
      onChange(e);
    }

    if (!noFormikOnChange) {
      await field.onChange(e);
    }
  };

  const setAsFocused = () => {
    // when the autocomplete is focused and interacted with we can confirm the user is not using a screen reader
    // this is used to disable tabbing selecting the hidden select used for screen readers
    setIsScreenReader(false);
    // Disable showing the suggestions when autoFocus = true until the user has interacted w/ the input
    if (!autoFocus || (autoFocus && focusCount > 0)) {
      setFocused(true);
      setHasBeenFocused(true);
    }
    setFocusCount((prevFocusCount) => prevFocusCount + 1);
    setHasSelectedSuggestion(false);
  };

  const handleFocus = () => setAsFocused();

  const renderLabel = () => {
    if (!labelText) {
      return null;
    }

    return (
      <Label htmlFor={idToUse}
             className="label-static"
             size={bsSize}>
        {icon && <FontAwesomeIcon icon={icon}/>} {labelText}
      </Label>
    );
  };

  const renderSuggestion = (suggestion: AutoCompleteSuggestion, index: number) => {
    const className = 'autocomplete-option' + (index === itemIndex ? ' highlight' : '');

    return <div
      className={className}
      onClick={() => selectItem(index, suggestion)}
      onKeyDown={handleKeyPress(suggestion)}>
      {suggestion.matchType === MatchType.START && renderStartMatch(field.value, suggestion.displayValue)}
      {suggestion.matchType === MatchType.END && renderEndMatch(field.value, suggestion.displayValue)}
      {suggestion.matchType === MatchType.MIDDLE && renderMiddleMatch(field.value, suggestion.displayValue)}
      {suggestion.matchType === MatchType.EXACT && <strong>{suggestion.displayValue}</strong>}
    </div>;
  };

  // renderSelect is used to render a FormikSelect component for screen readers only
  const renderSelect = () => {
    return <div className="sr-only sr-only-focusable">
      {renderLabel()}
      <FormikSelect {...field}
                    {...otherProps}
                    onFocus={handleFocus}
                    name={name}
                    id={`${idToUse}-sr-select`}
                    ariaLabel={(ariaLabel ? ariaLabel : labelText) || ''}
                    bsSize={bsSize}
                    disabled={disabled || isSubmitting}
                    invalid={isInvalid}
                    value={field.value}
                    tabIndex={isScreenReader ? 0 : -100}
                    onChange={handleChange}>
        <option value="">Select</option>
        {optionsAsObjects.map(option => <option key={option.value} value={option.value}>{option.displayValue}</option>)}
      </FormikSelect>
    </div>;
  };

  // renderAutoComplete renders the actual auto complete elements, these are hidden for screen readers
  const renderAutoComplete = () => {
    return <div aria-hidden="true">
      <FormGroup onFocus={handleFocus}>
        {renderLabel()}
        <Input {...field}
               {...otherProps}
               autoFocus={autoFocus}
               id={idToUse}
               type="text"
               aria-label={ariaLabel ? ariaLabel : labelText}
               bsSize={bsSize}
               disabled={disabled || isSubmitting}
               invalid={isInvalid}
               value={inputDisplayValue}
               onClick={handleFocus}
               onKeyDown={handleKeyPress(suggestions[itemIndex])}
               onChange={handleChange}
        />
        {showError && <FormFeedback>{meta.error}</FormFeedback>}
      </FormGroup>
      {showSuggestions &&
      <div className="autocomplete-options" ref={optionsContainerRef}>
        {hasNoResults && renderNoResults}
        {!hasNoResults && suggestions.map(renderSuggestion)}
      </div>}
    </div>;
  };

  return <div className="Autocomplete" ref={autocompleteRef}>
    {renderAutoComplete()}
    {renderSelect()}
  </div>;
};

export default FormikAutoComplete;