import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Badge, Button, Form, FormGroup} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {FormikPasswordInput} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {authenticationApi} from '../../api';
import {useSsoAppContext} from '../../hooks';
import {NewPasswordLoginFormFields} from '../../types';
import {newPasswordLoginFormSchema} from '../../schema';
import config from '../../config';

type Props = {
  lastCredentials: {username: string, password: string}
  onUnknownDevice: () => void
}

const NewPasswordLoginForm = ({
                                lastCredentials,
                                onUnknownDevice
                              }: Props) => {
  const navigate = useNavigate();
  const {setCurrentUser} = useSsoAppContext();
  const errorEl = useRef<HTMLParagraphElement>(null);
  const [serverError, setServerError] = useState(messages.PASSWORD_EXPIRED);
  const initialValues: NewPasswordLoginFormFields = {
    username: lastCredentials.username,
    currentPassword: lastCredentials.password,
    password: '',
    confirmPassword: ''
  };

  const handleSubmit = useCallback(async (values: NewPasswordLoginFormFields,
                                          formikHelpers: FormikHelpers<NewPasswordLoginFormFields>) => {
    try {
      await authenticationApi.newPasswordLogin(values);
      const currentUser = await authenticationApi.currentUser();
      setCurrentUser(currentUser);
      navigate('/dashboard');
    } catch (error: any) {
      const errorWithType = error as {message?: string, exception?: string | string[]};
      formikHelpers.setSubmitting(false);
      // Fetch will throw a TypeError if there was a network issue
      if (error instanceof TypeError) {
        setServerError(messages.UNABLE_TO_CONNECT_SERVICE);
      } else if (errorWithType.exception && errorWithType.exception.includes('RememberMeAuthenticationException')) {
        onUnknownDevice();
      } else if (error.message) {
        setServerError(error.message);
      } else {
        setServerError(messages.UNKNOWN_LOGIN_FAILURE);
      }
      // Focus the error message as part of ADA requirements
      errorEl?.current?.focus();
    }
  }, [
    navigate,
    onUnknownDevice,
    setCurrentUser
  ]);

  useEffect(() => {
    // Focus the error message as part of ADA requirements
    errorEl?.current?.focus();
  }, []);

  const loginErrorClassName = useMemo(() => {
    return `text-center text-danger p-2 ${serverError ? 'login-error' : ''}`;
  }, [
    serverError
  ]);

  const headerClassName = useMemo(() => {
    return `h4 mt-5 text-center ${serverError ? 'mb-3' : 'mb-4'}`;
  }, [
    serverError
  ]);

  return (
    <div className="UpdatePasswordForm">
      <h3 className={headerClassName}>
        Update Password
        {config.envName !== 'prod' &&
          <Badge color="warning" className="text-uppercase ml-2">
            {config.envName}
          </Badge>
        }
      </h3>
      <p className={loginErrorClassName}
         ref={errorEl}
         tabIndex={0}>
        {serverError}
      </p>
      <Formik initialValues={initialValues}
              validationSchema={newPasswordLoginFormSchema}
              onSubmit={handleSubmit}>
        {formikProps => (
          <Form onSubmit={formikProps.handleSubmit}>
            <FormikPasswordInput name="password"
                                 autoComplete="new-password"
                                 labelText="Create New Password"/>
            <FormikPasswordInput name="confirmPassword"
                                 autoComplete="new-password"
                                 labelText="Confirm New Password"/>
            <FormGroup className="text-center">
              <Button type="submit"
                      color="primary"
                      disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                Update
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewPasswordLoginForm;