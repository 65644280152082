import React, {useEffect, useMemo, useRef} from 'react';
import {Toast, ToastBody, ToastHeader} from 'reactstrap';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

import {useAlerts} from '../hooks';
import {Alert} from '../types';

const Alerts = () => {
  const {alerts, removeAlert} = useAlerts();

  const ref = useRef(null);
  useEffect(() => {
    const element = (ref?.current as unknown as HTMLElement);
    if (alerts.length > 0 && element !== null) {
      element.focus();
    }
  }, [alerts, ref]);

  const renderAlert = useMemo(() => ({
                                       id,
                                       icon,
                                       header,
                                       message,
                                       type
                                     }: Alert, index: number) => {
    return <CSSTransition key={index}
                          in={true}
                          timeout={300}
                          classNames="alert-transition">
      <Toast isOpen={true}
             fade={false}
             tabIndex={0}
             className={type}
             innerRef={ref}>
        <ToastHeader toggle={() => removeAlert(id)} icon={icon}>
          {header}
        </ToastHeader>
        <ToastBody>
          {message}
        </ToastBody>
      </Toast>
    </CSSTransition>;
  }, [removeAlert]);

  return (
    <div className="Alerts">
      <TransitionGroup className="transition-list" component={null}>
        {alerts.map(renderAlert)}
      </TransitionGroup>
    </div>
  );
};

export default Alerts;
