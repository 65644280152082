import {useCallback, useEffect, useState} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';

import {Alerts, MiFooter, NotFoundV2, SkipNav, SsoSessionExpirationModal} from '@reasoncorp/kyber-js';

import './font-awesome-icons';
import {authenticationApi, ssoApi} from './api';
import {useSsoAppContext} from './hooks';
import {Dashboard, EditUser, Faq, Login, Notifications, Reports, UserProfile, Users} from './containers';
import {SsoAppBar} from './components';
import branding from './branding';

const App = () => {
  const navigate = useNavigate();
  const {currentUser, setCurrentUser, isAdmin, isCsr, hasReportAccess} = useSsoAppContext();
  const [loading, setLoading] = useState(true);
  const [isSessionExpirationModalOpen, setIsSessionExpirationModalOpen] = useState(false);

  // Do initial load
  useEffect(() => {
    const loadData = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      // Attempt to load user from refresh token cookie if we aren't at the create/reset password screen
      if (window.location.pathname === '/login' && urlSearchParams.has('code')) {
        // Set loading to false so create/reset password screen is displayed
        setLoading(false);
      } else if (window.location.pathname === '/faq') {
        // Ignore the user logic if on the FAQ page
        setLoading(false);
      } else {
        // Attempt to load user from refresh token if the user didn't explicitly sign out
        // If an explicit sign out happened signOut=true will be part of the URL.
        // See KyberJs SsoSpringRestApi signOut method for more details.
        if (urlSearchParams.get('signOut')) {
          setLoading(false);
        } else {
          try {
            await authenticationApi.loginFromRefreshToken();
            const currentUser = await authenticationApi.currentUser();
            setCurrentUser(currentUser);
            setLoading(false);
            if (window.location.pathname === '/login') {
              navigate('/dashboard');
            }
          } catch (error) {
            if (window.location.pathname !== '/login') {
              await authenticationApi.redirectToLogin();
            } else {
              setLoading(false);
            }
          }
        }
      }
    };

    void loadData();
  }, [navigate, setCurrentUser]);

  const handleSetSessionExpirationModalOpen = useCallback((isOpen: boolean) => {
    ssoApi.setSessionWarningModalOpen(isOpen);
    setIsSessionExpirationModalOpen(isOpen);
  }, []);

  // Setup session monitoring
  useEffect(() => {
    const interval = authenticationApi.monitorSession(() => handleSetSessionExpirationModalOpen(true));
    return () => clearInterval(interval);
  }, [
    handleSetSessionExpirationModalOpen
  ]);

  return (
    <>
      {currentUser && <>
        <SkipNav/>
        <SsoAppBar currentUser={currentUser}
                   isAdmin={isAdmin}/>
      </>}
      {!loading &&
        <main role="main" id="content">
          <Routes>
            <Route path="/" element={<Navigate replace to="/login"/>}/>
            {branding.name === 'MISUITE' && <Route path="/faq" element={<Faq/>}/>}
            <Route path="/login" element={<Login/>}/>
            <Route path="/notifications" element={<Notifications/>}/>
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/user-profile" element={<UserProfile/>}/>
            {(isAdmin || isCsr) && <Route path="/users" element={<Users/>}/>}
            {(isAdmin || isCsr) && <Route path="/users/:userId" element={<EditUser/>}/>}
            {hasReportAccess && branding.name === 'MISUITE' &&
              <Route path="/reports" element={<Reports/>}/>}
            <Route path="*" element={<NotFoundV2/>}/>
          </Routes>
        </main>
      }
      <MiFooter/>
      <Alerts/>
      {currentUser && <SsoSessionExpirationModal isOpen={isSessionExpirationModalOpen}
                                                 setIsOpen={handleSetSessionExpirationModalOpen}
                                                 ssoApi={ssoApi}/>
      }
    </>
  );
};

export default App;