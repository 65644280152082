import * as Yup from 'yup';

const YupDate = (nullable: boolean = false, format: string = 'MM/dd/yyyy') => {
  const schema = Yup.date()
    .transform((value: Date | string | null, originalValue: Date | string | null) => {
      const isInvalidDateFormat = typeof originalValue === 'string' && originalValue.length < format.length;
      const returnValue = nullable ? '' : null;
      return isInvalidDateFormat ? returnValue : value;
    })
  return nullable ? schema.nullable() : schema;
}

export default YupDate;