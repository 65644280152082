import {ssoApi} from './apiUtils';

export const downloadEqualizationDirectorAndDeputyReport = () => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/misuite/equalization-director-deputy'
);

export const downloadForm4022VerificationReport = () => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/misuite/form-4022/verification'
);

export const downloadForm4022CertificationReport = () => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/misuite/form-4022/certification'
);

export const downloadMiMessagingAuditReport = () => ssoApi.restApiHelper.postWithBlobResponse(
  '/reports/misuite/mi-messaging'
);