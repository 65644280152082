import React, {useMemo} from 'react';
import {Col, Pagination, PaginationItem, PaginationLink, Row} from 'reactstrap';

export type LimitOption = {
  displayValue: string
  value: number | null
}

type Props = {
  className?: string
  message?: string
  resultLimit: number | null
  limitOptions?: LimitOption[]
  totalRecords: number
  handleClick: (limitOptionValue: number | null) => void
  recordName?: string
  pluralRecordName?: string
}

const ResultsLimiter = ({
                          className,
                          message = 'Show:',
                          resultLimit,
                          limitOptions = [
                            {displayValue: '50', value: 50},
                            {displayValue: '100', value: 100},
                            {displayValue: '250', value: 250},
                            {displayValue: 'All', value: null}
                          ],
                          totalRecords,
                          handleClick,
                          recordName = 'Record',
                          pluralRecordName = 'Records'
                        }: Props) => {
  const mainClassName = useMemo(() => 'ResultsLimiter', []);
  const classNameToUse = useMemo(() => className ? `${mainClassName} ${className}` : mainClassName, []);

  const renderLimitOption = useMemo(() => (limitOption: LimitOption, index: number) => {
    const selected = limitOption.value === resultLimit;
    return <PaginationItem key={index}>
      {selected && <PaginationLink className="selected" aria-current="true" role="button">
        {limitOption.displayValue}
      </PaginationLink>}
      {!selected && <PaginationLink role="button" onClick={() => handleClick(limitOption.value)}>
        {limitOption.displayValue}
      </PaginationLink>}
    </PaginationItem>;
  }, []);

  const renderLimitOptions = useMemo(() => limitOptions.map(renderLimitOption), [renderLimitOption]);

  const renderSummaryText = useMemo(() => {
    if (totalRecords === 1) {
      return `${totalRecords} ${recordName}`;
    }
    if (!resultLimit || resultLimit >= totalRecords) {
      return `${totalRecords} ${pluralRecordName}`;
    }
    if (totalRecords > resultLimit) {
      return `Showing ${resultLimit} of ${totalRecords} ${pluralRecordName} `;
    }
  }, [resultLimit, totalRecords]);

  return (
    <div className={classNameToUse}>
      <Row>
        <Col className="d-flex justify-content-start pt-4 pl-d-5 col-12 col-sm-12 col-md-6">
          <p className="text-muted mb-0">{renderSummaryText}</p>
        </Col>
        <Col className="d-flex justify-content-start justify-content-sm-start justify-content-md-end col-12 col-sm-12 col-md-6">
          <span className="text-muted py-4 pr-2">{message}</span>
          <Pagination className="text-muted pt-3 pr-4 mb-0">
            {renderLimitOptions}
          </Pagination>
        </Col>
      </Row>
    </div>
  );
};

export default React.memo(ResultsLimiter);
