import React, {memo, useCallback, useMemo} from 'react';
import {CustomInput} from 'reactstrap';

import {SelectTableCellData} from '../types';

type Props = {
  className?: string
  colspan?: number
  isHeader?: boolean
  htmlIdPrefix?: string // Use to avoid HTML id conflicts across tables, specify unique prefix for table and column
  itemId: number | string
  itemIds?: (number | string)[]
  selected: boolean
  onChange: (selectTableCellData: SelectTableCellData) => void
  ariaLabel?: string
}

const SelectTableCell = ({
                           className = 'align-middle text-center',
                           colspan,
                           isHeader = false,
                           htmlIdPrefix,
                           itemId,
                           itemIds = [],
                           selected,
                           onChange,
                           ariaLabel
                         }: Props) => {
  const Tag = useMemo(() => isHeader ? 'th' : 'td', [isHeader]);
  const scope = useMemo(() => isHeader ? 'col' : undefined, [isHeader]);
  const role = useMemo(() => isHeader ? 'columnheader' : undefined, [isHeader]);
  const derivedColSpan = useMemo(() => colspan ? colspan : undefined, [colspan]);
  const derivedClassName = useMemo(() => `SelectTableCell ${className}`, [className]);
  const id = useMemo(() => htmlIdPrefix ? `${htmlIdPrefix}-${itemId}` : itemId, [htmlIdPrefix, itemId]);

  const handleChange = useCallback(() => {
    onChange({itemId, itemIds, prevSelection: selected});
  }, [itemId, itemIds, selected]);

  const handleKeydown = useCallback((e: React.KeyboardEvent) =>
      e.key == 'Enter' ? onChange({itemId, itemIds, prevSelection: selected}) : null,
    [onChange, itemId, itemIds, selected]);


  return (
    <Tag className={derivedClassName}
         role={role}
         scope={scope}
         colSpan={derivedColSpan}
         onKeyDown={handleKeydown}>
      <CustomInput type="checkbox"
                   checked={selected}
                   id={id}
                   aria-label={ariaLabel}
                   onChange={handleChange}/>
    </Tag>
  );
};

export default memo(SelectTableCell);