import React, {memo, useMemo} from 'react';
import {Button, ButtonProps} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {BootstrapButtonSize} from '../types';

type ButtonState = {
  name: string
  text: string
  icon?: IconProp
  spinIcon?: boolean
}

interface Props extends ButtonProps {
  states: ButtonState[];
  activeState?: string;
  size?: BootstrapButtonSize;
}

const StatefulButton = ({
                          states,
                          activeState,
                          className,
                          ...miscProps
                        }: Props) => {
  const validStates = useMemo(() => states.map(state => {
    return state.name;
  }), []);

  const currentState = useMemo(() => {
    // Set to first state if no active state is set
    if (!activeState) {
      return states[0];
    } else {
      return states.find(state => {
        return state.name === activeState;
      });
    }
  }, [states]);


  if (!currentState) {
    throw new Error(`${activeState} is not a valid state. Valid states are: ${validStates}`);
  }


  return <Button className={className} {...miscProps}>
    {currentState.icon && <FontAwesomeIcon icon={currentState.icon}
                                           spin={currentState.spinIcon}
                                           className="mr-1"/>}
    {currentState.text}
  </Button>;
};

export default memo(StatefulButton);
