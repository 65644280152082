import React, {memo, ReactNode} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {BootstrapColor, BootstrapModalSize} from '../types';

type Props = {
  isOpen: boolean
  title?: string
  size?: BootstrapModalSize
  showCloseIcon?: boolean
  confirmButtonText?: string
  confirmButtonColor?: BootstrapColor
  confirmButtonDisabled?: boolean
  confirmButtonIcon?: IconProp
  confirmButtonIconSpin?: boolean
  confirmCallback: (e: React.MouseEvent<HTMLButtonElement>) => void
  cancelButtonText?: string
  cancelButtonColor?: BootstrapColor
  cancelButtonDisabled?: boolean
  cancelCallback: (e: React.MouseEvent<HTMLButtonElement>) => void
  children: ReactNode
}

const ConfirmationModal = ({
                             isOpen,
                             title,
                             size,
                             showCloseIcon = true,
                             confirmButtonText = 'Ok',
                             confirmButtonColor = 'success',
                             confirmButtonIcon,
                             confirmButtonIconSpin,
                             confirmButtonDisabled = false,
                             confirmCallback,
                             cancelButtonText = 'Cancel',
                             cancelButtonColor = 'secondary',
                             cancelButtonDisabled = false,
                             cancelCallback,
                             children
                           }: Props) =>
  (
    <Modal isOpen={isOpen}
           size={size}
           toggle={cancelCallback}
           aria-modal={true}
           returnFocusAfterClose={true}
           backdrop="static">
      <ModalHeader className="h5" toggle={showCloseIcon ? cancelCallback : undefined} tag="h2">
        {title}
      </ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
      <ModalFooter>
        <Button color={confirmButtonColor}
                onClick={confirmCallback}
                disabled={confirmButtonDisabled}>
          {confirmButtonIcon &&
            <FontAwesomeIcon icon={confirmButtonIcon} spin={confirmButtonIconSpin} className="mr-1"/>
          }
          {confirmButtonText}
        </Button>
        <Button color={cancelButtonColor}
                onClick={cancelCallback}
                disabled={cancelButtonDisabled}>
          {cancelButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );

export default memo(ConfirmationModal);